<template>
	<content-page>
		<template v-slot:content>
			<render-content id="thankyou"></render-content>
		</template>
	</content-page>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import ContentPage from "@c/ui/ContentPage";
export default {
	name: "Thankyou",
	components: {
		RenderContent,
		ContentPage,
	},
};
</script>
