<template>
    <v-alert v-if="content" color="primary" text v-html="content.html">
        
    </v-alert>
</template>

<script>
export default {
    name: "ContentFooter", 
    computed: {
        content(){

            return this.$store.state.content.data["genericfooter"]
        }
    },
    created(){
        this.$store.dispatch("content/fetchById", "genericfooter")
    }
}
</script>